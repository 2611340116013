* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    font-family: 'Helvetica';
  }

  html {
    scroll-behavior: smooth;
  }

/* styles.css */
body {
  background-color: black; /* Por defecto, el fondo es negro */
}
  
  .image__container5{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 8rem 1rem;
    max-width: 1500px;
    margin: auto;
    color: #fff;
  }
  .tservicios{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tservicios{
    text-align: center;
    padding-bottom: 3rem;
  }
  .tservicios h2{
    font-size: 3.5rem;
    color: #ff3333;
    padding-bottom: 1rem;
  }
  .tservicios p{
    font-size: 1.5rem;
    width: 60%;
    font-weight: 300;
  }

  .serviciostext{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .serviciob{
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
    
  .sl{
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  
  .sl img{
    width: 4.3rem;
    height: aut0;
  }
  
  .servicios{
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  
  .servicios li{
    font-size: 1.3rem;
  }
  
  .proximosp a{
    display: flex;
    align-items: center;
    gap: 3rem;
  }
  .proximosp img{
    width: 3.5rem;
    height: auto;
  }
  
  .proximosp span{
    font-size: 1.3rem;
    color: #ff3333;
  }

  .sliderservicios{display: flex; gap: 3rem;}

  .sliderhome{
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    max-width: 800px;
  }

  .swiper,.swiper img{
    width: 100%;}

    .logosslider2{
      display: flex;
      padding: 1rem;
      flex-direction: column;
      padding: 4rem 1rem;
      gap: 2rem;
      max-width: 1500px;
      margin: auto;
    }

    .logosv{
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 1rem;
      justify-content: center;
      align-items: center;
    }
    
    .logosv{
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }
    
    .logosv img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .titulof h1{
      font-size: 2.5rem;
      color: #ff3333;
      font-weight: 600;
    }
    
    .fbot{
      display: flex;
      flex-direction: column;
      gap: 2rem
      ;
    }
    .fbot1,
    .fbot2 {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
    }
    
    .fbot2{
      align-items: flex-end;
    }
    
    .f-izquierdo1 h1{
      color: #ff3333;
      padding-bottom: 1rem;
    }
    
    
    .f-izquierdo1 p{
      font-size: 1.2rem;
      color: #fff;
    }
    .f-derecho1 h1{
      font-size: 1.3rem;
      color: #ff3333;
    }
    .f-derecho1{
      display: flex;
      gap: .4rem;
    }
    .f-izquierdo2 p{
      color: #fff;
    }
    
    .f-derecho2 img{
      width: 10rem;
      height: auto;
    }
    
    .f-izquierdo1 span{
      color: #ff3333;
    }
    
    .f-izquierdo2 span{
      color: #ff3333;
    }
    .iconow{
      font-size: 1.1rem;
      padding: 0rem .2rem;
      color: #ff3333;
    }

    .slbody{
      display: none;
      }
 

 /*Responsive*/
@media(max-width:1200px){
  .sliderservicios{
    align-items: center;
    flex-direction: column;
  }
}

@media(max-width:1048px){
  .titulof h1{font-size: 2rem;}
  .logosv{
    max-width: 80%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }
 }


@media(max-width: 900px){
 .tservicios p{ width: 100%;}
}

@media(max-width:850px){
  .titulof h1{font-size: 1.5rem; text-align: center;}
  .f-izquierdo1 h1{font-size: 1.5rem;}
  .f-derecho1 h1{font-size: 1rem;}
  .f-izquierdo1 p,.f-izquierdo2 p{font-size: 1rem;}
  .f-derecho2 img{width: 6.5rem;}
}

@media(max-width:800px){
  .sliderhome{max-width: 600px;}
}

@media(max-width: 770px){
  .tservicios h2{font-size: 2.8rem;}
  .tservicios p{ font-size: 1.2rem;}
 }

 @media(max-width:630px){
  .logosv{
    display: none;
  }
  .slbody{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: center;
    align-items: center;
  }
  .slider img{
    width: 100%;
  }

  .slider{
    max-width: 100px;
  }

  

  
 }

@media(max-width:600px){
  .sliderhome{max-width: 400px;}
}
@media(max-width:530px){
  .servicios li, .proximosp span{font-size: 1rem;}
}

@media(max-width:500px){
  .fbot{gap: 1.5rem;}
  .fbot1,
  .fbot2 {flex-direction: column;}
  .fbot2{align-items: start;}
}

@media(max-width:400px){
  .sliderhome{max-width: 370px;}
}

@media(max-width:375px){
  .sliderhome{max-width: 350px;}
}

@media(max-width:320px){
  .sliderhome{max-width: 330px;}
}